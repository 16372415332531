<template>
  <div class="h-100">
    <transition mode="out-in" appear>
      <router-view></router-view>
    </transition>
    <notifications />
  </div>
</template>

<script>
const Colors = require('@/assets/colors.json');
export default {
  data() {
    return {
      faviconUrl: 'https://s3-medex-partners.s3.amazonaws.com/' + location.hostname.replace('www.', '') + '.ico',
    };
  },

  methods: {
    setFavicon() {
      var head = document.querySelector('head');
      var link = document.createElement('link');
      link.setAttribute('rel', 'shortcut icon');
      link.setAttribute('href', this.faviconUrl);
      head.appendChild(link);
    },
    setTitle() {
      var url = location.hostname.replace('dev-', '').replace('www.', '').replace('uat-', '').replace('stg-', '');
      let regex = /(.+?)[.-](.+?)[.](.+?)/;

      let match;
      if (url === 'localhost' || url.includes('exercito')) {
        document.title = 'Medex - Telemedicina';
      } else if (url.includes('habitossaudaveis')) {
        document.title = 'Hábitos Saudáveis - TEM Saúde';
      } else if (url.includes('lifsaude')) {
        document.title = 'Telemedicina - Lif Saúde';
      } else if (url.includes('corpoemente')) {
        document.title = 'Programa Corpo e Mente';
      } else if (url.includes('temsaude')) {
        document.title = 'Telemedicina - TEM Saúde';
      } else if (url.includes('saudemental')) {
        document.title = 'Saúde Mental - TEM Saúde';
      } else if (url.includes('klini')) {
        document.title = 'Klini - Telemedicina';
      } else if (url.includes('namu')) {
        document.title = 'Namu - Telemedicina';
      } else {
        match = url.match(regex);
        let formattedUrl = match[1].replace(/-/g, ' ') + ' - ' + match[2].replace(/-/g, ' ');
        formattedUrl = formattedUrl.replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
        document.title = formattedUrl;
      }
    },
    applyColorsToStyle(colors) {
      const root = document.documentElement;

      if (colors) {
        root.style.setProperty('--main-color', colors.main);
        root.style.setProperty('--main-color-dark', colors.main_dark);
        root.style.setProperty('--main-color-light', colors.main_light);
        root.style.setProperty('--page-bg', colors.page_bg);
        root.style.setProperty('--header-bg', colors.header_bg);
        root.style.setProperty('--card-bg', colors.card_bg);
        root.style.setProperty('--title', colors.title);
        root.style.setProperty('--sub-title', colors.sub_title);
        root.style.setProperty('--txt', colors.txt);
        root.style.setProperty('--link', colors.link);
        root.style.setProperty('--video-txt', colors.video_txt);
        root.style.setProperty('--btn-sec-bg', colors.btn_sec_bg);
        root.style.setProperty('--btn-pri-txt', colors.btn_pri_txt);
        root.style.setProperty('--btn-hover-txt', colors.btn_hover_txt);
        root.style.setProperty('--btn-dis-bg', colors.btn_dis_bg);
        root.style.setProperty('--btn-hour-bg', colors.btn_hour_bg);
        root.style.setProperty('--ipt-alert', colors.ipt_alert);
        root.style.setProperty('--ipt-label', colors.ipt_label);
        root.style.setProperty('--ipt-txt', colors.ipt_txt);
        root.style.setProperty('--ipt-border', colors.ipt_border);
        root.style.setProperty('--btn-share-bg', colors.btn_share_bg);
        root.style.setProperty('--box-bg-not', colors.box_bg_not);
        root.style.setProperty('--box-txt-not', colors.box_txt_not);
        root.style.setProperty('--box-bg-txt', colors.box_bg_txt);
        root.style.setProperty('--box-bg-btn', colors.box_bg_btn);
        root.style.setProperty('--box-border-off', colors.box_border_off);
        root.style.setProperty('--bg-progress-bar', colors.bg_progress_bar);
        root.style.setProperty('--progress-bar', colors.progress_bar);
      }
    },
    getColorsByUrl() {
      const colorMap = Colors;
      const url = location.hostname;
      for (let key in colorMap) {
        if (url.includes(key)) {
          return colorMap[key];
        }
      }

      return null;
    },
    setColors() {
      const url = location.hostname;

      const colors = this.getColorsByUrl(url);
      this.applyColorsToStyle(colors);
    },
  },
  mounted() {
    this.setFavicon();
    this.setTitle();
    this.setColors();
  },
};
</script>

<style lang="scss">
@import './assets/variables_clients';
@import './assets/scss/variables';

$theme-colors: (
  'primary': $btn-sec-txt,
  'secundary': $notification,
  'tertiary': $box-bg-not,
  'secondary': $txt,
  'gray': $txt,
  'red': $ipt-alert,
  'danger': $ipt-alert,
  'gray-600': $txt,
  'gray-700': $ipt-txt,
  'gray-900': $sub-title,
);

// Import Bootstrap and BootstrapVue source SCSS files
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

@import './assets/scss/main.scss';
</style>
