<template>
  <div id="app">
    <header id="header" v-if="$route.name !== 'meet'">
      <img alt="Logo" class="" :src="host" id="logo-header" />
    </header>

    <transition name="fade" mode="out-in">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'steps-layout',
  data() {
    return {
      host: 'https://s3-medex-partners.s3.amazonaws.com/' + location.hostname.replace('www.', '') + '.png',
    };
  },
  mounted() {
    if (this.isBrokers) {
      document.getElementById('logo-header').style.height = '42px';
      document.getElementById('header').style.padding = '0.875rem 1rem';
    } else if (this.isTemSaude) {
      document.getElementById('logo-header').style.height = '45px';
      document.getElementById('header').style.padding = '1.05rem 1rem';
    }
  },
  computed: {
    ...mapGetters({
      isBrokers: 'main/isBrokers',
      isTemSaude: 'main/isTemSaude',
    }),
  },
  methods: {
    ...mapActions({}),
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';

#app {
  display: flex;
  height: 100%;
  flex-direction: column;
}

header#header {
  background: var(--header-bg);
  padding: 1.5rem 1rem;
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 80px;
  display: flex;
  align-items: center;
  img {
    height: 32px;
    max-height: 100%;
    object-fit: contain;
  }
  // position: relative;
  @media screen and (max-width: 1200px) {
    padding: 1rem 1rem;
    height: 60px;

    img {
      height: auto !important;
    }
  }
}
</style>
